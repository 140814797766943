import React from 'react';

const Quote = () => {
    return (
        <div className='bg-black py-12 text-white '>
         <div className=' py-16 w-10/12 mx-auto'>   <p className='lg:text-4xl text-2xl py-2 lg:w-4/6 leading-10 tracking-wide'>  &quot;Referrals are not only a cost-effective way to hire, but
              they also lead to higher retention rates and better cultural
              fit.&quot;</p>
              <p className='py-3 text-base font-medium text-violet-400'>
              <span className="">Core Team - ReferralGuru</span></p></div>
        </div>
    );
};

export default Quote;