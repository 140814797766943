import React, { useState } from "react";
import logo from "../../assets/Logopit_1673180165306.png";
import axios from "axios";
import { toast } from "react-toastify";
import * as backend from "../../config";
function NewPassword() {
  const [otp, setOtp] = useState();
  const [pass, setPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const [email, setEmail] = useState(localStorage.getItem("temp-email"));
  const handleChangePass = (e) => {
    setPass(e.target.value);
  };
  const handleChangeConfPass = (e) => {
    setConfPass(e.target.value);
  };
  const handleChangeOTP = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        backend.backend+"proresetpassword",
        { email, otp, password: pass }
      );
      if (response) {
        toast.success("Password Changed Successfully !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        localStorage.removeItem("temp-mail");
        window.location.href = "/prologin";
      } else {
        toast.error("Something went wrong  !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="flex w-screen h-screen justify-center items-center bg-slate-100 flex-col lg:flex-row md:flex-row">
      <div className="flex flex-col">
        <img
          src={logo}
          alt="My Image"
          className="max-w-xs rounded-lg w-40 h-40"
        />
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col justify-start bg-white shadow-md px-5 w-1/4"
      >
        <h1 className="p-5">OTP</h1>
        <input
          className="p-2 rounded-md shadow-md w-auto"
          type={"text"}
          value={otp}
          onChange={handleChangeOTP}
          placeholder=""
          required
        />
        <h1 className="p-5">Password</h1>
        <input
          className="p-2 rounded-md shadow-md w-auto"
          type={"password"}
          value={pass}
          onChange={handleChangePass}
          placeholder=""
          required
        />
        <h1 className="p-5">Confirm Password</h1>
        <input
          className="p-2 rounded-md shadow-md w-auto"
          type={"text"}
          value={confPass}
          onChange={handleChangeConfPass}
          placeholder=""
          required
        />
        <button
          type="submit"
          className=" my-3 p-2 bg-black text-white rounded-md shadow-md"
        >
          Reset
        </button>
      </form>
    </div>
  );
}

export default NewPassword;
