import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineDashboard, MdLogout, MdPeopleAlt } from "react-icons/md";
// import { BsPersonCheck } from "react-icons/bs";
import {FaHandsHelping} from "react-icons/fa";
const handleLogout = () => {
  // console.log("Supposed to logout!");
  localStorage.clear();
  window.location.reload();
};

const Menus = [
  {
    title: "Incoming Requests",
    src: "/incomingrequest",
    icon: <MdPeopleAlt />,
  },
  // { title: "Approved for referral", src: "", icon: <BsPersonCheck /> },
  {
    title: "Help Request ",
    src: "/helprequest",
    icon: <FaHandsHelping />,
  },
  { title: "Logout", src: "", icon: <MdLogout /> },
];

const AdminSidebar = () => {
  const [open, setOpen] = useState(true);
  const toggleSidebar = () => {
    setOpen(!open);
  };
  return (
    <div className="h-screen flex items-end justify-end overflow-hidden">
      <button
        className="fixed lg:hidden z-90 top-10 right-8 bg-violet-800 w-5 h-10 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-violet-800   duration-300"
        onClick={toggleSidebar}
      >
        <span class="text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="w-6 m-auto"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
            />
          </svg>
        </span>
      </button>

      <div
        className={` ${
          open ? "w-48 px-2 " : "w-0 "
        } lg:w-72 bg-violet-800 h-screen relative duration-500`}
      >
        <div className=" justify-center mt-3">
          <h1
            className={`text-white font-medium text-2xl text-center duration-200 ${
              !open && "invisible"
            }`}
          >
            ReferralGuru
          </h1>
        </div>
        <ul className="pt-6">
          {Menus.map((Menu, index) =>
            Menu.title === "Logout" ? (
              <li
                key={index}
                className={`flex  rounded-md p-2 cursor-pointer hover:bg-violet-400 text-white text-sm items-center gap-x-4 
              ${Menu.gap ? "mt-9" : "mt-2"}  `}
                onClick={handleLogout}
              >
                {Menu.icon ? Menu.icon : <MdOutlineDashboard />}
                <span className="flex-1">{Menu.title}</span>
              </li>
            ) : (
              <Link to={Menu.src}>
                <li
                  key={index}
                  className={`flex  rounded-md p-2 cursor-pointer hover:bg-violet-400 text-white text-sm items-center gap-x-4 
              ${Menu.gap ? "mt-9" : "mt-2"}  `}
                >
                  {Menu.icon ? Menu.icon : <MdOutlineDashboard />}
                  <span className="flex-1">{Menu.title}</span>
                </li>
              </Link>
            )
          )}
        </ul>
      </div>
    </div>
  );
};

export default AdminSidebar;
