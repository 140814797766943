import React, { useState } from "react";
import LoginModal from "../../login_modal/LoginModal";

const Review = () => {
  const [users, setusers] = useState([
    { user: "Referral Seeker", link: "/slogin" },
    { user: "Referral Provider", link: "/prologin" },
    // { user: "Admin", link: "/adminlogin" },
  ]);
  return (
    <div className="bg-[#d9d9d9]">
      <div className="py-12 w-10/12 mx-auto">
        <div>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-16 justify-center items-center">
            <div>
              <img
                className="w-[412px] object-cover"
                alt="postedImage"
                src="https://images.unsplash.com/photo-1599658880436-c61792e70672?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDR8fGFuYWx5dGljc3xlbnwwfHx8fDE2NzMzNDUyMDQ&amp;ixlib=rb-4.0.3&amp;w=1200"
              />
            </div>
            <div className="">
              <p className="py-2 text-base font-medium text-violet-700">
                <span className="font-medium">Expert Resume Review</span>
              </p>
              <h1 className="lg:text-5xl text-2xl py-6 leading-9 tracking-normal font-bold ">
                Advanced analytics review on resume.
              </h1>
              <h3 className="font-bold text-2xl pb-1">Power reports</h3>
              <p className=" py-1 text-base">
                An advanced analytics review of your resume may involve a
                thorough examination of your technical skills, education, and
                experience in data analysis, statistical modeling, and data
                visualization. An advanced analytics review of your resume can
                help you demonstrate your value as a data-driven professional
                and can help you stand out in a competitive job market.
              </p>

              <div className="py-6 mt-2 flex flex-wrap justify-start gap-4">
                <div class="block w-full rounded-3xl border bg-black uppercase px-12 py-3 text-sm font-medium text-white hover:bg-violet-700 hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto hover:cursor-pointer">
                  <LoginModal options={users} title={"Get Started"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
