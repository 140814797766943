import React, { useState } from "react";
import resumeData from "../../data/resume.json";
import ResumeModal from "../TemplatesModal/ResumeModal";

const ResumeTemplates = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const openModal = (email) => {
    console.log("open modal");
    setSelectedCard(email);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedCard(null);
    setModalIsOpen(false);
  };
  return (
    <>
      {modalIsOpen && (
        <ResumeModal
          isOpen={modalIsOpen}
          onClose={closeModal}
          cardData={selectedCard}
          setModalIsOpen={setModalIsOpen}
          setSelectedCard={setSelectedCard}
        />
      )}
      <section class="overflow-hidden text-gray-700 ">
        <div class="container px-5 py-2 mx-auto lg:pt-12 lg:px-32">
          <div class="flex flex-wrap -m-1 md:-m-2">
            {resumeData.map((resume, index) => (
              <div class="flex flex-wrap w-1/3" key={index}>
                <div className="w-full p-1 md:p-2 group relative">
                  <a
                    className={`absolute inset-0 z-${
                      modalIsOpen ? "-1" : "10"
                    } bg-white text-center flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-50 duration-300`}
                  >
                    {/* <h1>Title</h1> */}
                    {/* <p class="mx-auto">Description</p> */}
                    <button
                      onClick={() => openModal(resume)}
                      className="px-4 py-2 text-sm text-white bg-purple-800"
                    >
                      Open Template
                    </button>
                  </a>

                  <a href="#" className="relative">
                    <img
                      className="bg-opacity-50 duration-300 mx-auto block object-cover object-center w-full h-full rounded-lg"
                      src={resume.preview}
                      alt=""
                    ></img>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ResumeTemplates;
