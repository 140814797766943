import React, { useState, useEffect } from "react";
import axios from "axios";
import Dropdown from "../Dropdown/Dropdown";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as backend from "../../config";

const RequestPortal = () => {
  const [email, setEmail] = useState(window.localStorage.getItem("email"));
  const [Job, setJob] = useState("");
  const [resumeLink, setResumeLink] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    async function fetchCompanies() {
      try {
        const response = await axios.get(backend.backend + "companies");
        setCompanies(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchCompanies();
  }, []);

  const handleRequest = async (e) => {
    e.preventDefault();
    if (!Job || !resumeLink || !selectedCompany) {
      toast.success("Fill All Fields!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    await axios
      .post(backend.backend + "requests", {
        email,
        request: {
          jobId: Job,
          resumeLink,
          companyName: selectedCompany,
        },
      })
      .then((response) => {
        // console.log(response);
        toast.success("Request Sent !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 400) {
          toast.info(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          console.log(error.response.data.message);
          toast.error("Error occured !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });

    // Adding to admin too
    await axios
      .post(backend.backend + "admingetrequest", {
        email,
        jobId: Job,
        resumeLink,
        companyName: selectedCompany,
      })
      .then((got) => {
        // console.log(got);
      })
      .catch((error) => {
        console.log(error.got);
        if (error.got.status === 409) {
          alert("Already present");
        } else {
          alert("Error Occured");
        }
      });
    setJob("");
    setResumeLink("");
  };

  const handleResumeChange = (e) => {
    setResumeLink(e.target.value);
  };

  const handleChangeJob = (e) => {
    setJob(e.target.value);
  };

  return (
    <div className="min-h-screen min-w-screen bg-slate-200 grid place-items-center overflow-auto">
      <div className="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
        <div className="bg-white shadow w-full rounded-lg divide-y divide-gray-200 drop-shadow-2xl">
          <form className="flex flex-col px-5 py-3 " onSubmit={handleRequest}>
            <label className="font-semibold text-sm text-gray-600 pb-1 pl-2 block">
              Company Name
            </label>
            <div>
              <Dropdown
                // className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
                options={companies}
                setSelectedCompany={setSelectedCompany}
                required
              />
            </div>
            <label className="font-semibold text-sm text-gray-600 pb-1 pl-2 block pt-5">
              Updated Resume Link
            </label>
            <input
              className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
              type={"string"}
              value={resumeLink}
              onChange={handleResumeChange}
              required
            />
            <label className="font-semibold text-sm text-gray-600 pb-1 pl-2 block">
              Job Id/Job Link
            </label>
            <input
              className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
              type={"text"}
              value={Job}
              onChange={handleChangeJob}
              required
            />
            <button
              type="submit"
              className="transition duration-200 bg-violet-800 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-lg font-semibold text-center inline-block cursor-pointer"
            >
              <span className="inline-block mr-2">Get me a Referral</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RequestPortal;
