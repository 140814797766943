import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as backend from "../../config";
const OTPModal = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState(window.localStorage.getItem("email"));
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };
  const handleVerifyOtp = (e) => {
    e.preventDefault();
  };

  const handleOtpSubmit = async (event) => {
    event.preventDefault();
    // console.log(otp);
    const response = await fetch(backend.backend + "verifyotp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, otp }),
    });
    const data = await response.json();
    // console.log(data.h);

    if (data.success) {
      toast.success("Account Created Successfully ! Login with Credentials.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsOpen(false);
      window.location.reload();
    } else {
      toast.error("Invalid OTP !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div
            className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Verify OTP.
                </h3>
                <form onSubmit={handleOtpSubmit}>
                  <input
                    type="text "
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={handleOtpChange}
                    className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
                  />
                  <button
                    type="submit"
                    className="transition duration-200 bg-violet-800 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-lg font-semibold text-center inline-block cursor-pointer"
                    // onClick={handleOtpSubmit}
                  >
                    <span className="inline-block mr-2">Verify OTP</span>
                    {/* add icon */}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OTPModal;
