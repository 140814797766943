import "./index.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import HelpRequest from "./pages/Admin/HelpRequest";
import ProfessionalLogin from "./pages/Professional/ProfessionalLogin";
import StudentLogin from "./pages/Student/StudentLogin";
import StudentSidebar from "./pages/Sidebar/StudentSidebar";
import ProfessionalSidebar from "./pages/Sidebar/ProfessionalSidebar";
import SSettings from "./pages/Student/Settings";
import SHelp from "./pages/Student/Help";
import JobTrack from "./pages/Student/JobTrack";
import RequestPortal from "./pages/Student/RequestPortal";
import Templates from "./pages/Student/Templates";
import PublicProfile from "./pages/Professional/PublicProfile";
import PSettings from "./pages/Professional/Settings";
import Company from "./pages/Company";
import RequestTable from "./pages/Professional/RequestTable";
import ReferredTable from "./pages/Professional/ReferredTable";
import IncomingRequest from "./pages/Admin/IncomingRequest";
import ProfHelp from "./pages/Professional/Help";
import { useEffect } from "react";
import AdminLogin from "./pages/Admin/AdminLogin";
import AdminSidebar from "./pages/Sidebar/AdminSidebar";
import ProResetPassword from "./pages/Professional/ResetPassword";
import ProNewPass from "./pages/Professional/NewPassword";
import StudentResetPassword from "./pages/Student/ResetPassword";
import StudentNewPassword from "./pages/Student/NewPassword";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
	const token = localStorage.getItem("token");
	const type = localStorage.getItem("type");

	useEffect(() => {
		if (!localStorage.getItem("token")) {
			if (
				window.location.pathname !== "/slogin" &&
				window.location.pathname !== "/criticalLogin" &&
				window.location.pathname !== "/prologin" &&
				window.location.pathname !== "/proNewPass" &&
				window.location.pathname !== "/proResetPass" &&
				window.location.pathname !== "/studentNewPassword" &&
				window.location.pathname !== "/studentResetPassword" &&
				window.location.pathname !== "/"
			)
				window.location.href = "/";
		}
	}, []);

	return (
		<div className='flex'>
			{(() => {
				if (token && type === "student") {
					return (
						<>
							<StudentSidebar />
							<div className='h-screen flex-1 w-screen overflow-auto'>
								<Routes>
									<Route
										path='/studenthelp'
										element={<SHelp />}
									/>
									<Route
										path='/studentsetting'
										element={<SSettings />}
									/>
									<Route
										path='/jobtrack'
										element={<JobTrack />}
									/>
									<Route
										path='/requestportal'
										element={<RequestPortal />}
									/>
									<Route
										path='/company'
										element={<Company />}
									/>
									<Route
										path='/templates'
										element={<Templates />}
									/>
									<Route
										path='*'
										element={
											<h1 className='flex justify-center font-bold text-slate-800'>
												404 Page not Available
											</h1>
										}
									/>
								</Routes>
							</div>
						</>
					);
				} else if (token && type === "professional") {
					return (
						<>
							<ProfessionalSidebar />
							<div className='h-screen flex-1 w-screen overflow-auto'>
								<Routes>
									<Route
										path='/publicprofile'
										element={<PublicProfile />}
									/>
									<Route
										path='/professionalhelp'
										element={<ProfHelp />}
									/>
									<Route
										path='/professionalsettings'
										element={<PSettings />}
									/>
									<Route
										path='/professionalsettings'
										element={<PSettings />}
									/>
									<Route
										path='/publicprofile'
										element={<PublicProfile />}
									/>
									<Route
										path='/RequestTable'
										element={<RequestTable></RequestTable>}
									/>
									<Route
										path='/ReferredTable'
										element={
											<ReferredTable></ReferredTable>
										}
									/>

									<Route
										path='*'
										element={
											<h1 className='flex justify-center font-bold text-slate-800'>
												404 Page not Available
											</h1>
										}
									/>
								</Routes>
							</div>
						</>
					);
				} else if (token && type === "admin") {
					return (
						<>
							<AdminSidebar />
							<div className='h-screen flex-1 w-screen overflow-auto'>
								<Routes>
									<Route
										path='/incomingrequest'
										element={<IncomingRequest />}
									/>
									<Route
										path='/helprequest'
										element={<HelpRequest />}
									/>
									<Route
										path='*'
										element={
											<h1 className='flex justify-center font-bold text-slate-800'>
												404 Page not Available
											</h1>
										}
									/>
								</Routes>
							</div>
						</>
					);
				} else {
					return (
						<div className='h-screen flex-1 w-screen overflow-auto'>
							<Routes>
								<Route path='/' element={<Home />} />
								<Route
									path='/proResetPass'
									element={<ProResetPassword />}
								/>
								<Route
									path='/proNewPass'
									element={<ProNewPass />}
								/>
								<Route
									path='/prologin'
									element={<ProfessionalLogin />}
								/>
								<Route
									path='/slogin'
									element={<StudentLogin />}
								/>
								<Route
									path='/criticalLogin'
									element={<AdminLogin />}
								/>
								<Route
									path='/studentNewPassword'
									element={<StudentNewPassword />}
								/>
								<Route
									path='/studentResetPassword'
									element={<StudentResetPassword />}
								/>
								<Route
									path='*'
									element={
										<h1 className='flex justify-center font-bold text-slate-800'>
											404 Page not Available
										</h1>
									}
								/>
							</Routes>
						</div>
					);
				}
			})()}
			<ToastContainer />
		</div>
	);
}

export default App;
