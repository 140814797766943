import React, { useState } from "react";
import { RiTeamFill } from "react-icons/ri";

const Network = () => {
  return (
    <div className="bg-[#d9d9d9] py-12 px-10 sm:grid-cols-4">
      <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 my-10 gap-10 px-auto py-auto ">
        <div className="shadow-lg p-12 rounded-3xl bg-white hover:scale-110 duration-500">
          <svg viewBox="0 0 982.8205714285714 1024" className="w-8 h-8">
            <path d="M894.286 73.143c49.714 0 88.571 40 88.571 89.143v296.571c0 273.714-218.857 492-490.857 492-273.143 0-492-218.286-492-492v-296.571c0-48.571 40.571-89.143 89.143-89.143h805.143zM492 681.143c17.143 0 34.286-6.857 46.857-18.857l230.857-221.714c13.143-12.571 21.143-30.286 21.143-48.571 0-37.143-30.286-67.429-67.429-67.429-17.714 0-34.286 6.857-46.857 18.857l-184.571 177.143-184.571-177.143c-12.571-12-29.143-18.857-46.286-18.857-37.143 0-67.429 30.286-67.429 67.429 0 18.286 7.429 36 20.571 48.571l231.429 221.714c12 12 29.143 18.857 46.286 18.857z"></path>
          </svg>
          <h1 className="py-4 font-medium text-3xl ">Free Referral</h1>
          <p>
            Maximize your chances of getting hired at top companies by
            leveraging our platform to get referred by industry professionals.
          </p>
        </div>
        <div className="shadow-lg p-12 rounded-3xl bg-white hover:scale-110 duration-500">
          <RiTeamFill size={35} />
          <h1 className="py-4 font-medium text-3xl">Premium Talents</h1>
          <p>
            Our platform connects industry professionals with top talent from
            around the world, at no cost to the hiring company. Our team will
            provide the best matching profiles based on your preferences,
            allowing you to choose the most suitable candidate.
          </p>
        </div>
        <div className="shadow-lg p-12 rounded-3xl bg-white hover:scale-110 duration-500">
          <svg viewBox="0 0 1024 1024" className="w-8 h-8">
            <path d="M768 598v-86h-234l-86 86h320zM256 598h106l294-294q16-16 0-30l-76-76q-16-14-30 0l-294 294v106zM854 86q34 0 59 25t25 59v512q0 34-25 60t-59 26h-598l-170 170v-768q0-34 25-59t59-25h684z"></path>
          </svg>
          <h1 className="py-4 font-medium text-3xl">Free Review</h1>
          <p>
            Receive a cost-free assessment from industry experts. For each
            rejection, you will be provided with a thorough evaluation to aid in
            your personal and professional growth.
          </p>
        </div>
        <div className="shadow-lg p-12 rounded-3xl bg-white hover:scale-110 duration-500">
          <svg viewBox="0 0 1024 1024" className="w-8 h-8">
            <path d="M710 72l242 242-242 240h186v342h-342v-342h156l-240-240v156h-342v-342h342v186zM128 896v-342h342v342h-342z"></path>
          </svg>
          <h1 className="py-4 font-medium text-3xl">Get Best Templates </h1>
          <p>
            Stand out from the competition with our platform's professional
            resume, cover letter, and email templates.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Network;
