import React, { useState } from "react";
import { Link } from "react-router-dom";

const LoginModal = ({ options, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      <div
        onClick={openModal}
        className="cursor-pointer text-center font-medium tracking-wide  transition-colors duration-200 hover:text-teal-accent-400"
      >
        {title}
      </div>
      {isOpen && (
        <div
          className="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"
          onClick={closeModal}
        >
          <div
            className="fixed inset-0 transition-opacity"
            // onClick={closeModal}
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div
            className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Choose an option
                </h3>
                <div className="mt-2">
                  {options.map((option, index) => (
                    <Link to={option.link}>
                      <button
                        key={index}
                        className="bg-gray-100 p-2 m-4 rounded-lg hover:bg-gray-200 text-black focus:outline-none"
                      >
                        {option.user}
                      </button>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginModal;
