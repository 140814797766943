import React from "react";
import footerLogo from "../../../assets/nav-logo.png";
import {
  AiOutlineLinkedin,
  AiOutlineInstagram,
  AiOutlineFacebook,
} from "react-icons/ai";
import { SlSocialTwitter } from "react-icons/sl";
const Footer = () => {
  return (
    <div className="pt-14 bg-black  text-gray-100">
      <div className="pb-5">
        <h1 className="text-center text-xl font-medium tracking-wide">
          Contact
        </h1>
        <h1 className="text-center text-lg link tracking-wide">
          mailtoreferralguru@gmail.com
        </h1>
      </div>
      <footer className="px-4 divide-y flex flex-col justify-center">
        <div className="container flex flex-col align-baseline py-10 mx-auto space-y-8 lg:flex-row lg:space-y-0">
          <div className="lg:w-1/3">
            <a
              rel="noopener noreferrer"
              href="#"
              className="flex justify-center space-x-3 lg:justify-start"
            >
              <div className="flex items-center justify-center rounded-full  ">
                <div className=" rounded-full p-2">
                  {" "}
                  <img className="w-14 h-14 " src={footerLogo} alt="" />
                </div>
              </div>
              {/* <span className="self-center text-2xl font-semibold">Brand name</span> */}
              {/* <h4 className="lg:text-3xl py-2 text-lg uppercase font-bold bg-gradient-to-r from-fuchsia-400 to-violet-200 bg-clip-text text-transparent">
              Referral<span className="bg-gradient-to-r from-violet-200 to-fuchsia-400 bg-clip-text text-transparent  lg:px-5 px-2">Guru</span>
            </h4> */}
              <h1 className="lg:text-2xl py-2 text-lg font-medium">
                ReferralGuru
              </h1>
            </a>
          </div>
          <div className="grid grid-cols-2 text-sm gap-x-3 gap-y-8 lg:w-2/3 sm:grid-cols-4">
            <div className="space-y-3">
              <h3 className="tracking-wide uppercase  text-gray-50">
                Service Offered
              </h3>
              <ul className="space-y-1">
                <li>
                  <a rel="noopener noreferrer" href="#">
                    Get Referrals
                  </a>
                </li>
                <li>
                  <a rel="noopener noreferrer" href="#">
                    Give Referrals
                  </a>
                </li>
                <li>
                  <a rel="noopener noreferrer" href="#">
                    Resume Review
                  </a>
                </li>
                <li>
                  <a rel="noopener noreferrer" href="#">
                    Premium Templates
                  </a>
                </li>
              </ul>
            </div>
            <div className="space-y-3">
              <h3 className="tracking-wide uppercase  text-gray-50">Company</h3>
              <ul className="space-y-1">
                <li>
                  <a rel="noopener noreferrer" href="#">
                    About{" "}
                  </a>
                </li>
                <li>
                  <a rel="noopener noreferrer" href="#">
                    Team
                  </a>
                </li>
                <li>
                  <a rel="noopener noreferrer" href="#">
                    News
                  </a>
                </li>
                <li>
                  <a rel="noopener noreferrer" href="#">
                    Careers
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className="space-y-3">
				<h3 className="uppercase  text-gray-50">Developers</h3>
				<ul className="space-y-1">
					<li>
						<a rel="noopener noreferrer" href="#">Public API</a>
					</li>
					<li>
						<a rel="noopener noreferrer" href="#">Documentation</a>
					</li>
					<li>
						<a rel="noopener noreferrer" href="#">Guides</a>
					</li>
				</ul>
			</div> */}
            <div className="space-y-3">
              <div className="uppercase  text-gray-50">Connect</div>
              <div className="flex justify-start space-x-3">
                <a
                  rel="noopener noreferrer"
                  target={"_blank"}
                  href="https://www.linkedin.com/company/referralguru/"
                  title="Linkedin"
                  className="flex items-center p-1"
                >
                  <AiOutlineLinkedin size={32} />
                </a>
                <a
                  rel="noopener noreferrer"
                  target={"_blank"}
                  href="https://www.instagram.com/tryreferralguru/"
                  title="Instagram"
                  className="flex items-center p-1"
                >
                  <AiOutlineInstagram size={32} />
                </a>
                <a
                  rel="noopener noreferrer"
                  target={"_blank"}
                  href="https://twitter.com/tryreferralguru"
                  title="Twitter"
                  className="flex items-center p-1"
                >
                  <SlSocialTwitter size={32} />
                </a>
                <a
                  rel="noopener noreferrer"
                  target={"_blank"}
                  href="https://www.facebook.com/tryreferralguru/"
                  title="Facebook"
                  className="flex items-center p-1"
                >
                  <AiOutlineFacebook size={32} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="py-6 text-sm text-center  text-gray-400">
          © 2023 ReferralGuru. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default Footer;
