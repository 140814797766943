import React, { useState } from "react";
import logo from "../../assets/Logopit_1673180165306.png";
import axios from "axios";
import { toast } from "react-toastify";
import * as backend from "../../config";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const changeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(backend.backend + "proforgotpassword", {
        email,
      });
      if (response) {
        localStorage.setItem("temp-email", email);
        toast.success("OTP Sent !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        window.location.href = "/proNewPass";
      } else {
        toast.error("Something went wrong !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.log(error);
    }
    // console.log(email);
  };
  return (
    <>
      <div className="flex w-screen h-screen justify-center items-center bg-slate-100 flex-col lg:flex-row md:flex-row">
        <div className="flex flex-col">
          <img
            src={logo}
            alt="My Image"
            className="max-w-xs rounded-lg w-40 h-40"
          />
        </div>
        <form onSubmit={handleSubmit} className="items-center">
          <h1 className="p-5 font-bold flex justify-center">
            Enter your email
          </h1>
          <input
            className="p-2 rounded-md border-black w-auto shadow-md"
            type={"email"}
            value={email}
            onChange={changeEmail}
            placeholder="sample@amazon.com"
            required
          />
          <button
            type="submit"
            className="ml-2 my-3 p-2 bg-black text-white rounded-md shadow-md"
          >
            Sent OTP
          </button>
        </form>
      </div>
    </>
  );
}

export default ResetPassword;
