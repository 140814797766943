import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as backend from "../../config";
const Help = () => {
  const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(window.localStorage.getItem("email"));
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);

  const toastNotification = () => {
    toast.success("We'll get back to you !", {
      position: toast.POSITION.TOP_RIGHT,
      toastId: "success1",
    });
  };

  const handleSubmit = async (e) => {
    // console.log("Btn clicked");
    e.preventDefault();
    const formData = new FormData();
    formData.append("firstName", firstName);
    // formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("message", message);
    if (file) {
      formData.append("file", file, file.name);
    }
    try {
      const response = await fetch(backend.backend + "studenthelp", {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      // console.log(data.status);
      setEmail("");
      setFirstName("");
      setMessage("");
      toastNotification();
    } catch (error) {
      toast.error("Something went wrong !", {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "error",
      });
      console.error(error);
    }
  };

  return (
    <div
      style={{ flex: 1 }}
      className="flex items-center justify-center h-screen bg-slate-200"
    >
      <form class="w-full max-w-lg" onSubmit={handleSubmit}>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-first-name"
            >
              Name
            </label>
            <input
              class="appearance-none block w-full bg-white border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-first-name"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
            >
              Message
            </label>
            <textarea
              class=" no-resize appearance-none block w-full bg-white border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-40 resize-none"
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 block">
              Any file upload
            </label>
            <input
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
              className="hidden"
            />
            <label
              htmlFor="file"
              className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded inline-block cursor-pointer"
            >
              Choose a file
            </label>
          </div>
        </div>
        <div class="md:flex md:items-center">
          <div class="md:w-1/3">
            <button
              class="shadow bg-black hover:bg-violet-800 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              type="submit"
            >
              Send
            </button>
          </div>
          <div class="md:w-2/3"></div>
        </div>
      </form>
    </div>
  );
};

export default Help;
