import React from "react";
import Modal from "react-modal";

const ResumeModal = ({
  isOpen,
  onClose,
  cardData,
  setModalIsOpen,
  setSelectedCard,
}) => {
  const closeModal = () => {
    setSelectedCard(null);
    setModalIsOpen(false);
  };

  const openWebsite = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal"
      overlayClassName="modal-overlay"
    >
      {/* Render the card data inside the modal */}
      <div className="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
        <div className="fixed inset-0 transition-opacity" onClick={closeModal}>
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div
          className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <img
              className="mt-4 mx-auto w-3/4 h-3/4"
              src={cardData.preview}
              alt="Preview"
            />
            <button
              className="mt-4 px-4 py-2 text-white rounded bg-violet-800 hover:scale-110 duration-500"
              onClick={() => openWebsite(cardData.link)}
            >
              Open Template
            </button>
            <button
              className="fixed bottom-4 right-4 px-4 py-2 text-white rounded bg-gray-500 hover:bg-gray-700"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ResumeModal;
