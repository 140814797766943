import React, { useState, useEffect } from "react";
import axios from "axios";
import * as backend from "../../config";

const FeedbackModal = ({ setFeedBack, setIsModalOpen }) => {
  const [isOpen, setIsOpen] = useState(true);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const [remark, setRemark] = useState("");

  const handleRemarkChange = (e) => {
    setRemark(e.target.value);
  };

  const handleSubmit = async (e) => {
    setFeedBack(remark);
    setIsModalOpen(false);
    setIsOpen(false);
    setRemark("");
    const response = await axios.post(backend.backend + "adminreject", {
      email: window.localStorage.getItem("tempEmail"),
      jobId: window.localStorage.getItem("tempjobId"),
      tempresumeLink: window.localStorage.getItem("tempresumeLink"),
      resumeLink: window.localStorage.getItem("tempresumeLink"),
      status: "Rejected",
      remarks: remark,
    });
    window.localStorage.removeItem("tempjobId");
    window.localStorage.removeItem("tempEmail");
    window.localStorage.removeItem("tempresumeLink");
    window.localStorage.removeItem("tempstatus");
    // console.log("this is the data", response.data);
  };

  return (
    <>
      {isOpen && (
        <div className="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={closeModal}
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div
            className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Feedback for the Applicant
                </h3>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text "
                    placeholder="Tips for improvement"
                    value={remark}
                    onChange={handleRemarkChange}
                    className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
                  />
                  <button
                    type="submit"
                    className="transition duration-200 bg-violet-800 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-lg font-semibold text-center inline-block cursor-pointer"
                  >
                    <span className="inline-block mr-2">Submit Remarks</span>
                    {/* add icon */}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackModal;
