import React, { useEffect, useState } from 'react';
import axios from "axios";
import * as backend from "../../config";
const HelpRequest = () => {
  const [helpData, setHelpData] = useState([]);

  useEffect(() => {
    axios.get( backend.backend+"helpdata").then((response) => {
      setHelpData(response.data);
    });
  }, []);

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Name
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Email
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Message
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
  {helpData.map((message) => (
    <tr key={message.id} className="hover:bg-gray-100 transition duration-150 ease-in-out">
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">{message.firstName}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">{message.email}</div>
      </td>
      <td className="px-6 py-4 whitespace-wrap">
        <div className="text-sm font-medium text-gray-900">
          {message.message.length > 20
            ? `${message.message.substring(0, 20)}...`
            : message.message
          }
        </div>
        {message.message.length > 20 &&
          <div className="text-sm text-gray-500 mt-2 hover:text-gray-900 transition duration-150 ease-in-out">
            {message.message}
          </div>
        }
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        {message.file &&
          <a href={message.file.url} target="_blank" rel="noopener noreferrer">
            View file
          </a>
        }
      </td>
    </tr>
  ))}
</tbody>

    </table>
  );
}

export default HelpRequest;
