import React from "react";

const Video = () => {
  return (
    <div className="flex justify-center items-center pt-8">
      <iframe
        width="800"
        height="450"
        src="https://www.youtube.com/embed/tagxt9Poaig?autoplay=1"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Tutorial Video"
      ></iframe>
    </div>
  );
};

export default Video;
