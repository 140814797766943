import React, { useEffect, useState } from "react";
import navLogo from "../../assets/Logopit_1673179781121.png";
import Dropdown from "../Dropdown/Dropdown";
import LoginModal from "../login_modal/LoginModal";

const Nav = () => {
  // const [selectedOption, setSelectedOption] = useState("");

  // const handleChange = (event) => {
  //   setSelectedOption(event.target.value);
  //   switch (event.target.value) {
  //     case "professional-login":
  //       window.location.href = "/prologin";
  //       break;
  //     case "student-login":
  //       window.location.href = "/slogin";
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [users, setusers] = useState([
    { user: "Referral Seeker", link: "/slogin" },
    { user: "Referral Provider", link: "/prologin" },
    // { user: "Admin", link: "/adminlogin" },
  ]);

  return (
    <div className="">
      <div class="bg-gray-100 text-black w-10/12 mx-auto rounded-full ">
        <div class="px-4 py-3 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
          <div class="relative flex items-center justify-between">
            <div className="relative flex items-center justify-between">
              <img
                className="lg:w-12 lg:h-12 md:w-10 md:h-10 w-8 h-8 mr-2"
                src={navLogo}
                alt=""
              />{" "}
              <div class="items-center justify-start hidden space-x-8 lg:flex uppercase 2xl:mr-96 xl:mr-48">
                <div className="ml-6">
                  <a
                    href="/"
                    aria-label="ABOUT US"
                    title="ABOUT US"
                    class="font-medium tracking-wide   transition-colors duration-200 hover:text-teal-accent-400 "
                  >
                    ABOUT US
                  </a>
                </div>
                <div className="ml-6">
                  <a
                    href="/"
                    aria-label="HOW IT WORKS"
                    title="HOW IT WORKS"
                    class="font-medium tracking-wide  transition-colors duration-200 hover:text-teal-accent-400"
                  >
                    HOW IT WORKS
                  </a>
                </div>
                {/* <li>
                <a
                  href="/"
                  aria-label="PRICE"
                  title="PRICE"
                  class="font-medium tracking-wide  transition-colors duration-200 hover:text-teal-accent-400"
                >
                  Price
                </a>
              </li> */}
                {/* <li>
                <a
                  href="/"
                  aria-label="CAREERS"
                  title="CAREERS"
                  class="font-medium tracking-wide  transition-colors duration-200 hover:text-teal-accent-400"
                >
                  CAREERS
                </a>
              </li> */}
              </div>
            </div>
            <div class=" items-center hidden space-x-8 lg:flex">
              <div>
                {/* <select value={selectedOption} onChange={handleChange}>
                <option
                  // href="/"
                  class="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide  transition duration-200 rounded-full shadow-md bg-black text-white hover:bg-blue-700 focus:shadow-outline focus:outline-none uppercase"
                  aria-label="Sign up"
                  title="Sign up" >
                 Sign Up
                </option>
                <option 
                value="professional-login">Give Referral</option>
                <option value="student-login">Get Referral</option>
                </select> */}
                {/* <a href="/" aria-label="Get Started" title="Get Started">
                  Login
                </a> */}
                <LoginModal options={users} title={"Login"} />
              </div>
              <div className="hover:text-teal-accent-400 inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide  transition-color duration-200 rounded-full shadow-md bg-black hover:bg-violet-800 text-white focus:shadow-outline focus:outline-none uppercase touch-pan-up">
                <LoginModal options={users} title={"Get Started"} />
                {/* <a
                  href="/"
                  className="hover:text-teal-accent-400 inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide  transition-color duration-200 rounded-full shadow-md bg-black hover:bg-violet-800 text-white focus:shadow-outline focus:outline-none uppercase touch-pan-up"
                  aria-label="Get Started"
                  title="Get Started"
                >
                  Get Started
                </a> */}
              </div>
            </div>
            <div class="lg:hidden">
              <button
                aria-label="Open Menu"
                title="Open Menu"
                class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline"
                onClick={() => setIsMenuOpen(true)}
              >
                <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                  />
                </svg>
              </button>
              {isMenuOpen && (
                <div class="absolute top-0 left-0 w-full">
                  <div class="p-5 bg-white border rounded shadow-sm">
                    <div class="flex items-center justify-between mb-4">
                      <div>
                        <div className=" flex justify-between items-center ">
                          <img
                            className="lg:w-12 lg:h-12 md:w-10 md:h-10 w-8 h-8"
                            src={navLogo}
                            alt=""
                          />{" "}
                          {/* <h1 className="mx-2">Referral Guru</h1> */}
                        </div>
                      </div>
                      <div>
                        <button
                          aria-label="Close Menu"
                          title="Close Menu"
                          class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <nav>
                      <ul class="space-y-4 uppercase">
                        <li>
                          <a
                            href="/"
                            aria-label="ABOUT US"
                            title="ABOUT US"
                            class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                          >
                            ABOUT US
                          </a>
                        </li>
                        <li>
                          <a
                            href="/"
                            aria-label="HOW IT WORKS"
                            title="HOW IT WORKS"
                            class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                          >
                            HOW IT WORKS
                          </a>
                        </li>
                        {/* <li>
                          <a
                            href="/"
                            aria-label="PRICE"
                            title="PRICE"
                            class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                          >
                            PRICE
                          </a>
                        </li> */}
                        {/* <li>
                          <a
                            href="/"
                            aria-label="CAREERS"
                            title="CAREERS"
                            class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                          >
                            CAREERS
                          </a>
                        </li> */}
                        <div>
                          <div
                            class="inline-flex items-center justify-center h-8 px-3 font-medium tracking-wide  transition duration-200 rounded-full shadow-md bg-black text-white hover:bg-blue-700 focus:shadow-outline focus:outline-none uppercase"
                            aria-label="Sign up"
                            title="Sign up"
                          >
                            <LoginModal options={users} title={"Login"} />
                          </div>
                        </div>
                        <div>
                          <div
                            href="/"
                            class="inline-flex items-center justify-center h-8 px-3 font-medium tracking-wide  transition duration-200 rounded-full shadow-md bg-black hover:bg-blue-700 text-white focus:shadow-outline focus:outline-none uppercase touch-pan-up"
                            aria-label="Get Started"
                            title="Get Started"
                          >
                            <LoginModal options={users} title={"Get Started"} />
                          </div>
                        </div>
                      </ul>
                    </nav>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
