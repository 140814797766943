import React, { useState, useEffect } from "react";
// import data from "../../data/dataTable.json";
import * as backend from "../../config";
import axios from "axios";
function ReferredTable() {
  const [tableData, setTableData] = useState([]);
  const [email, setEmail] = useState(window.localStorage.getItem("email"));

  useEffect(() => {
    // console.log(email);
    try {
      async function fetchData() {
        const response = await axios.post(backend.backend + `proData`, {
          email,
        });
        // console.log("this is the data", response.data.accepted);

        setTableData(response.data.accepted);
        // setDetails(response.data);
      }

      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, [tableData]);
  return (
    <div className="bg-slate-200 h-full">
      <div class="w-auto mx-auto">
        <h1 className="flex justify-center pt-7 text-xl sm:text-2xl md:text-3x">
          Candidates You Have Referred
        </h1>
        <div class="bg-white shadow-md rounded my-6">
          <table class="text-left w-full border-collapse">
            <thead>
              <tr>
                <th class="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                  S.No
                </th>
                <th class="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                  Date
                </th>
                <th class="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                  Email
                </th>
                <th class="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                  Job Link
                </th>
                <th class="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                  Resume
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((student, index) => (
                <tr class="hover:bg-grey-lighter" key={index}>
                  <td class="py-4 px-6 border-b border-grey-light">
                    {index + 1}
                  </td>
                  <td class="py-4 px-6 border-b border-grey-light">
                    {student.createdAt.substr(0, 10)}
                  </td>
                  <td class="py-4 px-6 border-b border-grey-light">
                    {student.email}
                  </td>
                  <td class="py-4 px-6 border-b border-grey-light">
                    {student.jobId}
                  </td>
                  <td class="py-4 px-6 border-b border-grey-light">
                    {student.resumeLink}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ReferredTable;
