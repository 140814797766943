import React, { useState } from "react";

const Dropdown = ({ options, setSelectedCompany }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select Company");

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // onSelect(option);
    setSelectedCompany(option);
  };

  return (
    <div className="relative">
      <button
        className="flex items-center justify-between w-full text-left p-2 rounded-lg bg-white border border-gray-300 focus:outline-none"
        onClick={toggleDropdown}
      >
        <span className="text-gray-700">{selectedOption}</span>
        <svg
          className="w-4 h-4 ml-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          {isOpen ? (
            <path d="M5.293 6.707L10 10.414l4.293-3.707L14.586 9z" />
          ) : (
            <path d="M14.293 5.293L10 9.586 5.293 5.293 4 6.586 10 13 16 6.586z" />
          )}
        </svg>
      </button>
      {isOpen && (
        <ul
          className="absolute z-10 w-full py-1 bg-white text-gray-700 border border-gray-300 rounded-lg shadow-lg"
          style={{ maxHeight: "10rem", overflowY: "scroll" }}
        >
          {options.map((option) => (
            <li
              key={option}
              className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
              onClick={() => handleOptionSelect(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
