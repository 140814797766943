import React, { useState } from "react";
import LoginModal from "../../login_modal/LoginModal";

const Tracking = () => {
  const [users, setusers] = useState([
    { user: "Referral Seeker", link: "/slogin" },
    { user: "Referral Provider", link: "/prologin" },
    // { user: "Admin", link: "/adminlogin" },
  ]);
  return (
    <div className="py-12  bg-white w-10/12 mx-auto">
      <div>
        <p className="py-3 text-base font-medium text-violet-700">
          <span className="font-medium">Application Tracking</span>
        </p>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-14">
          <div className="">
            <h1 className="lg:text-5xl text-2xl py-7 leading-9 tracking-normal font-bold ">
              Track all you job applications at one place.
            </h1>
            <p className=" py-1 text-base">
              Having a centralized system for tracking job applications can be
              extremely helpful for organizing and managing your job search. By
              keeping all of your applications in one place, you can easily
              reference the status of each application, follow up on outstanding
              applications, and avoid the risk of missing an important deadline
              or interview opportunity.
            </p>
            <div class="mt-8 flex flex-wrap justify-start gap-4">
              <div class="block w-full rounded-3xl border bg-black uppercase px-12 py-3 text-sm font-medium text-white border-black hover:bg-violet-700 hover:border-white hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto hover:cursor-pointer">
                <LoginModal options={users} title={"Get Started"} />
              </div>
            </div>
          </div>
          <div>
            <img
              className="w-[412px] object-cover"
              alt="image"
              src="https://images.unsplash.com/photo-1665686310429-ee43624978fa?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE5fHxidXNpbmVzc3xlbnwwfHx8fDE2NzMyODYzNTk&amp;ixlib=rb-4.0.3&amp;w=500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tracking;
