import React from "react";
import Modal from "react-modal";

// Modal.setAppElement("#root");

const ReadOnlyModal = ({
  isOpen,
  onClose,
  cardData,
  setModalIsOpen,
  setSelectedCard,
}) => {
  const closeModal = () => {
    setSelectedCard(null);
    setModalIsOpen(false);
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal"
      overlayClassName="modal-overlay"
    >
      {/* Render the card data inside the modal */}
      <div className="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
        <div className="fixed inset-0 transition-opacity" onClick={closeModal}>
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div
          className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div className="mt-3 text-center sm:mt-5">
              {/* <h1
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                Cold Email Template
              </h1> */}
              <h3 className="text-xl mb-4">{cardData.title}</h3>
              <p className="text-gray-700 font-bold mb-2">
                Subject: {cardData.subject}
              </p>
              <p className="text-gray-700">{cardData.body}</p>
              <button
                className="mt-4 px-4 py-2 text-white rounded bg-violet-800 hover:scale-110 duration-500"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReadOnlyModal;
