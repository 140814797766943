import React, { useState } from "react";
import LoginModal from "../../login_modal/LoginModal";

const Templates = () => {
  const [users, setusers] = useState([
    { user: "Referral Seeker", link: "/slogin" },
    { user: "Referral Provider", link: "/prologin" },
    // { user: "Admin", link: "/adminlogin" },
  ]);
  return (
    <div>
      <div className="py-12  bg-white w-10/12 mx-auto">
        <div>
          <p className=" text-base font-medium text-violet-700">
            <span className="font-medium">Expertly Crafted Templates</span>
          </p>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-14">
            <div>
              <h1 className="lg:text-5xl text-2xl py-6 leading-10  font-bold ">
                Professional templates for email, cover letter.
              </h1>
              <p className="py-1 text-base">
                Powerful templates for emails and cover letters can be useful
                tools for presenting yourself as a professional and effectively
                communicating your skills and experiences to potential
                employers. These templates may include clear and concise
                language that highlights your relevant qualifications and
                achievements.
              </p>
              <div className="py-6 mt-2 flex flex-wrap justify-start gap-4">
                <div class="block w-full rounded-3xl border bg-black uppercase px-12 py-3 text-sm font-medium text-white hover:bg-violet-700 hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto hover:cursor-pointer">
                  <LoginModal options={users} title={"Get Started"} />
                </div>
              </div>
            </div>
            <div>
              <img
                className="w-[412px] object-cover"
                alt="image"
                src="https://images.unsplash.com/photo-1629019621374-94c3bae51815?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDV8fHRlbXBsYXRlc3xlbnwwfHx8fDE2NzMzNDUyODk&amp;ixlib=rb-4.0.3&amp;w=1200"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Templates;
