import React from "react";
import Pricing from "../pricing/Pricing";
import Video from "../video/Video";

const HowItWork = () => {
  return (
    <div className="bg-gradient-to-r from-[#2B498F] to-[#8C3A8D]  opacity-95 py-20">
      <div className="text-center text-white">
        <h5 className="text-base pb-5 font-medium text-xl text-violet-200">
          How it works?
        </h5>
        <h1 className="lg:text-5xl md:text-3xl text-lg leading-10 font-bold">
          Being social and getting leads has never{" "}
        </h1>
        <br />{" "}
        <span className="py-2 lg:text-5xl md:text-3xl text-lg leading-10 font-bold">
          been easier
        </span>
      </div>
      {/* <div className="bg-[#5228F5] bg-blue-5000 text-white py-12 px-10 my-8 w-9/12 mx-auto rounded-3xl">
        <h2 className="text-3xl font-bold pb-1"> 1 — Sign up</h2>
        <p className="py-4 text-base leading-5 tracking-wide">
          Click on the "Sign Up" button to create your account.
        </p>
        <button className="py-3">
          {" "}
          <a
            class="block w-full rounded-full bg-black uppercase   px-12 py-4 text-sm font-medium text-white   hover:bg-white hover:text-black focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
            href="/get-started"
          >
            Get Started
          </a>
        </button>
      </div> */}
      <Video></Video>
      {/* <div className=" w-9/12 mx-auto py-12 "> */}
      {/* <div className="grid lg:grid-cols-2 grid-cols-1 gap-16 ">
          <div className="bg-white rounded-badge py-10 px-12 text-black hover:scale-110 duration-500">
            <h1 className="lg:text-4xl md:text-3xl text-lg leading-10 font-bold text-center py-5 tex-black">
              2 — Want to get Referred in your dream company?
            </h1>
            <li className="px-4 py-3 text-base">
              Connect with people in your professional network who may have
              connections or insight into the company or role you are interested
              in.
            </li>
            <li className="px-4 py-3 text-base">
              Follow up with your contact to ensure that your referral has been
              submitted and to express your appreciation for their assistance.
            </li>
            <li className="px-4 py-3 text-base">
              Remember to inquire about any referral rewards or incentives that
              may be available for successful referrals.
            </li>
          </div>
          <div className="bg-black rounded-badge py-10 px-12 text-white hover:scale-110 duration-500">
            <h1 className="lg:text-4xl md:text-3xl text-lg leading-10 font-bold text-center py-5 ">
              2 — Want to give referral and earn rewards !
            </h1>
            <li className="px-4 py-3 text-base">
              Make sure you have a good understanding of the job requirements
              and the qualifications of the person you are recommending.
            </li>
            <li className="px-4 py-3 text-base">
              Consider the value that the person you are referring could bring
              to the company and how they would fit in with the team and company
              culture.
            </li>
            <li className="px-4 py-3 text-base">
              Let it be special! :) Coming Soon
            </li>
          </div>
        </div> */}
      {/* </div> */}
      {/* <Pricing></Pricing> */}
    </div>
  );
};

export default HowItWork;
