import React from "react";
import DangerousHTML from "dangerous-html/react";
import "./faqs.css";
const FAQ = () => {
  return (
    <section className=" md:w-9/12 lg:w-8/12 w-10/12 mx-auto py-10">
      <div className="text-center py-10">
        <span className="lg:text-5xl md:text-4xl text-3xl font-semibold leading-relaxed">
          Frequently asked questions
        </span>
      </div>
      <div className="home-accordion ">
        <div data-faq="closed" className="accordionContainer py-2 my-2  px-3">
          <div className="containers">
            <span className="text-2xl  font-medium leading-10">
              How does your platform work?
            </span>
            <svg viewBox="0 0 1024 1024" className="accordionIcon h-6 w-6">
              <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
            </svg>
          </div>
          <div className="accordionContent py-3">
            <span className="text-lg">
              To get started, simply create a profile on our platform.
              You&apos;ll be asked to provide information about your education,
              work experience, and professional skills. Once your profile is
              complete, you can start connecting with employees at top companies
              and receiving job referrals. Then, our team will conduct a
              thorough review of your resume. If it is deemed suitable for
              referral, your profile will be verified and established on our
              platform. However, if it does not meet our requirements, we will
              provide you with constructive feedback and guidelines for
              corrections.
            </span>
          </div>
        </div>
        <div className="accordionContainer py-2 my-2  px-3">
          <div className="containers">
            <span className="text-2xl  font-medium leading-10 ">
              {" "}
              Is there a fee to use your platform?
            </span>
            <svg viewBox="0 0 1024 1024" className="accordionIcon h-6 w-6">
              <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
            </svg>
          </div>
          <div className="accordionContent py-3">
            <span className="text-lg">
              Our platform is fully accessible without charge to both students
              and working professionals. Students can receive complimentary
              referrals based on their resume, while professionals can track and
              acquire top talent from our platform for their respective
              companies.
            </span>
          </div>
        </div>
        <div data-faq="closed" className="accordionContainer py-2 my-2 px-3">
          <div className="containers">
            <span className="text-2xl  font-medium leading-10">
              How do I connect with employees at top companies?
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <svg viewBox="0 0 1024 1024" className="accordionIcon h-6 w-6">
              <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
            </svg>
          </div>
          <div className="accordionContent py-3">
            <span className="text-lg">
              Kindly submit your most current and comprehensive resume, along
              with the relevant job identification number, to one of our
              esteemed employees from a top-tier company. Our team of experts
              will conduct a thorough review of your qualifications and if
              deemed suitable, forward it to the relevant employees. If a job
              opportunity that aligns with your credentials is available, you
              will be recommended for the position. However, in the event of a
              rejection, we will provide you with constructive feedback and
              suggestions for improvement.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </div>
        </div>
        <div data-faq="closed" className="accordionContainer py-2 my-2 px-3">
          <div className="containers">
            <span className="text-2xl  font-medium leading-10">
              How do I know if I&apos;m a good fit for a job referral?
            </span>
            <svg viewBox="0 0 1024 1024" className="accordionIcon h-6 w-6">
              <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
            </svg>
          </div>
          <div className="accordionContent py-3">
            <span className="text-lg">
              We encourage you to apply for job referrals that align with your
              education, work experience, and professional skills. If you are
              selected for a referral, it means that the employee at the company
              believes you are a good fit for the position and is willing to
              recommend you for an interview.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </div>
        </div>
        <div data-faq="closed" className="accordionContainer py-2 my-2 px-3">
          <div className="containers">
            <span className="text-2xl  font-medium leading-10">
              How often are new job openings added to your platform?
            </span>
            <svg viewBox="0 0 1024 1024" className="accordionIcon h-6 w-6">
              <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
            </svg>
          </div>
          <div className="accordionContent py-3">
            <span className="text-lg ">
              New job openings are added to our platform regularly. We encourage
              you to check back frequently to see the latest opportunities
              available.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <div>
        <DangerousHTML
          html={`<script>
// Accordion - Code Embed

const accordionContainers = document.querySelectorAll(".accordionContainer"); // All accordion containers
const accordionContents = document.querySelectorAll(".accordionContent"); // All accordion content
const accordionIcons = document.querySelectorAll(".accordionIcon"); // All accordion icons

accordionContents.forEach((accordionContent) => {
    accordionContent.style.display = "none"; //Hides all accordion contents
});

accordionContainers.forEach((accordionContainer, index) => {
    accordionContainer.addEventListener("click", () => {
        accordionContents.forEach((accordionContent) => {
            accordionContent.style.display = "none"; //Hides all accordion contents
        });

        accordionIcons.forEach((accordionIcon) => {
            accordionIcon.style.transform = "rotate(0deg)"; // Resets all icon transforms to 0deg (default)
        });

        if (accordionContainer.classList.contains("accordion-open")) { // Checks if this container has class "accordion-open"
            accordionContainer.classList.remove("accordion-open"); // Removes class "accordion-open"
        } else {
            accordionContainers.forEach((accordionContainer) => {
                accordionContainer.classList.remove("accordion-open"); // Removes class "accordion-open" from all containers
            });

            accordionContainer.classList.add("accordion-open"); // Adds class "accordion-open" to this container
            accordionContents[index].style.display = "flex"; // Shows accordion content
            accordionIcons[index].style.transform = "rotate(180deg)"; // Rotates accordion icon 180deg
        }
    });
});

/*
Here's what the above is doing:
    1. Selects all accordion containers, contents, and icons
    2. Hides all accordion contents
    3. Adds an event listener to each accordion container
    4. When an accordion container is clicked, it:
        - Hides all accordion contents
        - Resets all icon transforms to 0deg (default)
        - Checks if this container has class "accordion-open"
            - If it does, it removes class "accordion-open"
            - If it doesn't, it:
                - Removes class "accordion-open" from all containers
                - Adds class "accordion-open" to this container
                - Shows accordion content
                - Rotates accordion icon 180deg
*/
</script>`}
        ></DangerousHTML>
      </div>
    </section>
  );
};

export default FAQ;
