import React, { useEffect, useState } from "react";
import OTPModal from "../OTPModal/OTPModal";
import axios from "axios";
import * as backend from "../../config";
import logo from "./../../assets/Logopit_1673179781121.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Modal from 'react-modal'

function ProfessionalLogin() {
	const [email, setEmail] = useState("");
	const [emailPro, setEmailPro] = useState(null);
	const [isSignup, setIsSignup] = useState(false);
	const [password, setPassword] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [confPass, setConfPass] = useState("");
	const [openModal, setOpenModal] = useState(false);
	// const [otp,setOtp] = useState('');

	useEffect(() => {
		try {
			async function fetchEmail() {
				const response = await axios.post(
					backend.backend + `getFreeEmail`
				);
				setEmailPro(response.data);
			}
			fetchEmail();
		} catch (error) {
			console.log(error);
		}
	}, []);

	const handleChangeConfirmPassword = (e) => {
		setConfPass(e.target.value);
	};
	const handleChange = () => {};
	// console.log("Hello")
	const switchMode = (e) => {
		setIsSignup(!isSignup);
		isSignup == false &&
			toast(
				"We assure you that your personal data, including your working email, is securely encrypted and will not be sold. Our robust security measures employ advanced hashing techniques that make it impossible to trace your data. The sole purpose of collecting your data is to ensure authentication and transparency for the student, and it will not be used for any other purpose.",
				{
					draggable: false,
					position: toast.POSITION.TOP_RIGHT,
					autoClose: false,
				}
			);
	};

	const handleCompanyNameChange = (e) => {
		setCompanyName(e.target.value);
	};

	const handleEmailChange = (e) => {
		// const regex= /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		setEmail(e.target.value);
	};

	const emailDomainCheck = (e) => {
		e.preventDefault();

		var isSpam = false;

		const emailDomain = email.split("@")[1];

		for (let i = 0; i < emailPro.length; i++) {
			if (emailPro[i].domains.includes(emailDomain)) {
				isSpam = true;
				break;
			}
		}

		if (isSpam == true) {
			toast.warning(
				"Please use a professional email address to help us identify your company and establish trust. Using an email from your company domain, rather than a personal or free email service, demonstrates your commitment to your professional identity and helps us to ensure the security and confidentiality of your account",
				{
					position: toast.POSITION.TOP_RIGHT,
				}
			);
		} else {
			handleSubmit();
		}
	};

	const handleChangepass = (e) => {
		setPassword(e.target.value);
	};

	// const closeModel = () => {
	//   setOpenModal(false);
	// };
	const handleSubmit = (e) => {
		if (isSignup) {
			if (password !== confPass) {
				toast.error("Password did not matching !", {
					position: toast.POSITION.TOP_RIGHT,
				});
				return;
			} else {
				setOpenModal(true);
				window.localStorage.setItem("email", email);
				window.localStorage.setItem("companyname", companyName);

				fetch(backend.backend + "sendotp", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ email, password, companyName }),
				})
					.then((response) => response.json())
					.then((data) => {
						if (data.success) {
							toast.success("OTP Send !", {
								position: toast.POSITION.TOP_RIGHT,
							});
						} else {
							toast.error("OTP Already Sent !", {
								position: toast.POSITION.TOP_RIGHT,
							});
						}
					})
					.catch((error) => {
						console.error(error);
						// alert("Server error");
						toast.error("Server Error !", {
							position: toast.POSITION.TOP_RIGHT,
						});
					});
			}
		} else {
			fetch(backend.backend + "professionallogin", {
				method: "POST",
				crossDomain: true,
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"Access-Control-Allow-Origin": "*",
				},
				body: JSON.stringify({
					email,
					password,
				}),
			})
				.then((res) => res.json())
				.then((data) => {
					console.log(data);
					// console.log(data.token)
					if (data.success) {
						window.localStorage.setItem("token", data.token);
						window.localStorage.setItem("email", data.user.email);
						window.localStorage.setItem("type", data.user.role);
						window.localStorage.setItem(
							"company",
							data.user.companyName
						);
						toast.success("Login Successfull !", {
							position: toast.POSITION.TOP_RIGHT,
						});
						window.location.href = "/RequestTable";
						window.location.reload();
					} else {
						toast.error("Invalid Credentials !", {
							position: toast.POSITION.TOP_RIGHT,
						});
					}
				});
		}
	};

	const forgotPass = (e) => {
		window.location.href = "/proResetPass";
	};

	return (
		<div className='flex-1 min-h-screen bg-gradient-to-r from-bg-blue via-purple-500 to-bg-purple flex flex-col justify-center'>
			{openModal && <OTPModal status={openModal} />}
			<div className='p-10 xs:p-0 mx-auto md:w-full md:max-w-md'>
				<div className='bg-white shadow w-full rounded-lg divide-y divide-gray-200'>
					<form className='px-5' onSubmit={emailDomainCheck}>
						<div className='chat-notification-logo-wrapper flex justify-center'>
							<img
								className='chat-notification-logo h-1/4 w-1/4'
								src={logo}
								alt='ChitChat Logo'
							/>
						</div>
						{isSignup && (
							<>
								<label className='font-semibold text-sm text-gray-600 pb-1 block'>
									Company Name
								</label>
								<input
									type='text'
									className='border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full'
									onChange={handleCompanyNameChange}
									value={companyName}
								/>
							</>
						)}
						<label className='font-semibold text-sm text-gray-600 pb-1 block'>
							Working Mail
						</label>
						<input
							type='email'
							className='border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full'
							onChange={handleEmailChange}
							value={email}
						/>
						<label className='font-semibold text-sm text-gray-600 pb-1 block'>
							Password
						</label>
						<input
							type='password'
							className='border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full'
							onChange={handleChangepass}
							value={password}
						/>
						{isSignup && (
							<>
								<label class='font-semibold text-sm text-gray-600 pb-1 block'>
									Confirm Password
								</label>
								<input
									type='password'
									handleChange={handleChange}
									onChange={handleChangeConfirmPassword}
									value={confPass}
									class='border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full'
								/>
							</>
						)}
						<button
							// type="submit"
							className='transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block'>
							{isSignup ? (
								<span className='inline-block mr-2'>
									Sign Up
								</span>
							) : (
								<span className='inline-block mr-2'>Login</span>
							)}
							{/* add icon */}
						</button>
					</form>
					<div className='py-5'>
						{!isSignup && (
							<>
								<div className='text-center whitespace-nowrap'>
									<button
										className='transition duration-200 mx-5 px-5 py-4 cursor-pointer font-normal text-sm rounded-lg text-bg-stone-700'
										onClick={forgotPass}>
										<span className='inline-block ml-1'>
											Forgot Password
										</span>
									</button>
								</div>
							</>
						)}
						<div className='text-center whitespace-nowrap'>
							<button
								className='transition duration-200 mx-5 px-5 py-4 cursor-pointer font-normal text-sm rounded-lg text-bg-stone-700'
								onClick={switchMode}>
								<span className='inline-block ml-1'>
									{isSignup
										? "Already Have an account? Sign In "
										: "Don't have an account! Sign Up"}
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ProfessionalLogin;
