import React, { useState } from "react";
import LoginModal from "../../login_modal/LoginModal";
import Nav from "./../Nav";

const Header = () => {
  const [users, setusers] = useState([
    { user: "Referral Seeker", link: "/slogin" },
    { user: "Referral Provider", link: "/prologin" },
    // { user: "Admin", link: "/adminlogin" },
  ]);
  return (
    <div className="bg-gradient-to-r from-[#2B498F] to-[#8C3A8D]  opacity-95 pt-4">
      {/* <Navbar></Navbar> */}
      <Nav></Nav>
      {/* Header */}
      <section class=" text-white">
        <div class="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center">
          <div class="mx-auto max-w-7xl text-center">
            <h1 class="text-white lg:text-7xl md:text-5xl  font-bold  text-3xl leading-relaxed">
              Find your dream job through
              <span class="sm:block"> trusted connections! </span>
            </h1>

            {/* <p class="mx-auto mt-4 max-w-xl sm:text-xl sm:leading-relaxed">
      Find your dream job through trusted connections!
      </p> */}

            <div class="mt-8 flex flex-wrap justify-center gap-4">
              <div class="block w-full rounded-3xl border bg-black uppercase px-12 py-3 text-sm font-medium text-white border-black hover:bg-transparent hover:border-white hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto hover:cursor-pointer">
                <LoginModal options={users} title={"Get Started"} />
              </div>
              {/* <a
                class="block w-full rounded-3xl border px-12 py-3 text-sm uppercase font-medium text-white hover:bg-black focus:outline-none focus:ring active:bg-blue-500 sm:w-auto"
                href="/about"
              >
                Learn More
              </a> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Header;

/* 

 <div className='w-8/12 mx-auto'><h1 className='xl:text-6xl lg:text-5xl md:text-4xl sm:text-3xl text-2xl pt-80 pb-4 text-center text-white font-bold'>Find your dream job through <br /> trusted connections! <div  className='mx-auto  py-4 '>
            <button className='btn lg:px-6  btn-xs lg:btn-md bg-black text-center text-sm  text-white hover:text-black hover:bg-white  rounded-full '>Get Started</button></div></h1>
 
            </div>

*/
/*    class="bg-gradient-to-r from-white via-purple-200 to-violet-400 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl"*/
