import React, { useState } from "react";
import CardView from "../components/card/CardView";
import coverLetters from "./../../data/coverLetter.json";
import ReadOnlyModal from "../TemplatesModal/ReadOnlyModal";

const CoverLettersTemplates = () => {
  const [emailData, setEmailData] = useState(coverLetters);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const openModal = (email) => {
    console.log("open modal");
    setSelectedCard(email);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedCard(null);
    setModalIsOpen(false);
  };

  return (
    <>
      {modalIsOpen && (
        <ReadOnlyModal
          isOpen={modalIsOpen}
          onClose={closeModal}
          cardData={selectedCard}
          setModalIsOpen={setModalIsOpen}
          setSelectedCard={setSelectedCard}
        />
      )}
      <section class="overflow-hidden text-gray-700 ">
        <div class="container px-5 py-2 mx-auto lg:pt-12 lg:px-32">
          <div class="flex flex-wrap -m-1 md:-m-2">
            {coverLetters.map((coverLetter, index) => (
              <div class="flex flex-wrap w-1/3" key={index}>
                <div
                  className="w-full p-1 md:p-2 group relative"
                  onClick={() => openModal(coverLetter)}
                >
                  <a className="bg-white text-center flex flex-col items-center justify-center hover:scale-110 duration-500 bg-opacity-50 duration-300">
                    <CardView
                      className="shadow cursor-pointer mx-auto block object-cover object-center w-full h-full rounded-lg"
                      email={coverLetter}
                    />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default CoverLettersTemplates;
