import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import FeedbackModal from "../../pages/FeedbackModal/FeedbackModal";
import * as backend from "../../config";
const IncomingRequest = () => {
  const [incomingData, setIncomingData] = useState([]);
  const [feedBack, setFeedBack] = useState("");
  const [sendData, setSendData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    try {
      async function fetchData() {
        const response = await axios.get(backend.backend + "adminreqlist");
        // console.log("this is the data", response.data);

        setIncomingData(response.data);
        // console.log("Hello");
        // console.log("Hello", incomingData);
      }

      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, [incomingData]);

  // const changeMotalStatus = () => {
  //   setIsModalOpen(!isModalOpen);
  // };
  const handleReject = async (row) => {
    setIsModalOpen(!isModalOpen);
    // console.log(row);
    window.localStorage.setItem("tempEmail", row.email);
    window.localStorage.setItem("tempjobId", row.jobId);
    window.localStorage.setItem("tempresumeLink", row.resumeLink);
  };
  // console.log("This is feedback", feedBack);

  const handleAccept = async (row) => {
    let dataSent = { ...row };
    setSendData(dataSent);
    // console.log(sendData);
    // console.log(row);

    const response = await axios.post(backend.backend + "globalrequest", row);
  };

  return (
    <>
      {isModalOpen && (
        <FeedbackModal
          setFeedBack={setFeedBack}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      <div class="w-auto mx-auto">
        <h1 className="flex justify-center pt-7 text-xl sm:text-2xl md:text-3x">
          Incoming Request
        </h1>

        {incomingData.length > 0 ? (
          <div class="bg-white shadow-md rounded my-6">
            <table class="text-left w-full border-collapse">
              <thead>
                <tr>
                  <th class="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                    S.No
                  </th>
                  <th class="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                    Company Name
                  </th>
                  <th class="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                    Job Id /Job Link
                  </th>
                  <th class="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                    Remarks
                  </th>

                  <th class="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                    Resume Link
                  </th>
                  <th class="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {incomingData.map((e, i) => (
                  <tr class="hover:bg-grey-lighter" key={i}>
                    <td class="py-4 px-6 border-b border-grey-light">
                      {i + 1}
                    </td>
                    <td class="py-4 px-6 border-b border-grey-light">
                      {e.companyName}
                    </td>
                    <td class="py-4 px-6 border-b border-grey-light">
                      {e.jobId}
                    </td>
                    <td class="py-4 px-6 border-b border-grey-light">
                      {e.remarks}
                    </td>

                    <td class="py-4 px-6 border-b border-grey-light">
                      <a
                        href="{http://whatismyscreenresolution.net/}"
                        target="_blank"
                      >
                        {" "}
                        {e.resumeLink}
                      </a>
                    </td>
                    <td class="py-4 px-6 border-b border-grey-light">
                      <button
                        class="bg-green-500 text-white font-bold py-1 px-3 rounded text-xs bg-green hover:bg-green-dark m-1"
                        onClick={() => handleAccept(e)}
                        type="submit"
                      >
                        Accept
                      </button>
                      <button
                        class="bg-red-500 text-white font-bold py-1 px-3 rounded text-xs bg-blue hover:bg-blue-dark m-1"
                        htmlFor="my-modal-5"
                        type="submit"
                        data-item={e}
                        onClick={() => handleReject(e)}
                      >
                        Reject
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="flex items-center justify-center flex-col pt-10  ">
            <h1>No Requests Yet!</h1>
            <div
              className="mt-10 inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-violet-600 border-r-transparent align-[-0.125em] text-danger motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default IncomingRequest;
