import React, { useState } from "react";

import FAQ from "../components/faq/FAQ";
import Footer from "../components/footer/Footer";
import Network from "../components/network/Network";
import Quote from "../components/quote/Quote";
import Review from "../components/review/Review";
import Templates from "../components/templates/Templates";
import Tracking from "../components/tracking/Tracking";

// import './home.css'
import Header from "./../components/header/Header";
import HowItWork from "./../components/howItWork/HowItWork";

function Home() {
  return (
    <div className="w-full mx-auto home select-none">
      <Header></Header>
      <Network></Network>
      <Quote></Quote>
      <Tracking></Tracking>
      <Review></Review>
      <Templates></Templates>
      <HowItWork></HowItWork>
      <FAQ></FAQ>

      <Footer></Footer>
    </div>
  );
}

export default Home;
