import React, { useState, useEffect } from "react";
import cardContent from "../database/data";
import axios from "axios";
import Dropdown from "./Dropdown/Dropdown";
import * as backend from "../config";
import companyImg from "../assets/company.jpg";

const Company = () => {
  const [query, setQuery] = useState("");
  const handleChangeCompany = (e) => {
    setQuery(e.target.value);
    // console.log(query);
  };
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    async function fetchCompanies() {
      try {
        const response = await axios.get(backend.backend + "companies");
        setCompanies(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchCompanies();
  }, []);
  return (
    <div className="min-h-screen bg-slate-200 mx-auto justify-center ">
      <h1 className="text-center font-semibold text-3xl py-5">
        Get Referred On Your Dream Company.
      </h1>
      <h3 className="text-center font-semibold py-3">
        Search your desired company:
      </h3>
      <div className="flex justify-center ">
        <div>
          <input
            type="text "
            placeholder="Company Name"
            value={query}
            onChange={handleChangeCompany}
            className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
          />
        </div>
      </div>
      <div className="container mx-auto py-10 px-8">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6  ">
          {query === ""
            ? companies.map((name) => (
                <div className="shadow-lg rounded-t-lg bg-white">
                  <img
                    src={companyImg}
                    alt=""
                    className="rounded-t-lg object-contain p-2"
                  />
                  <div className="p-5">
                    <h3 className="text-3xl font-bold text-slate-700 mb-3">
                      {name}
                    </h3>
                    <p className="text-xs font-normal text-gray-600">
                      Jobs are waiting for you!
                    </p>
                  </div>
                </div>
              ))
            : companies.map((name, index) => {
                if (name.toLowerCase().includes(query.toLowerCase()))
                  return (
                    <div
                      className="shadow-lg rounded-t-lg bg-white"
                      key={index}
                    >
                      <img
                        src={companyImg}
                        alt=""
                        className="rounded-t-lg object-contain p-2"
                      />
                      <div className="p-5">
                        <h3 className="text-3xl font-bold text-slate-700 mb-3">
                          {name}
                        </h3>
                        <p className="text-xs font-normal text-gray-600">
                          Jobs are waiting for you!
                        </p>
                      </div>
                    </div>
                  );
              })}
        </div>
      </div>
    </div>
  );
};

export default Company;

// const Company = () => {
//   return (
//     <div className="min-h-screen bg-slate-300">
//         <h1 className='text-center font-bold py-5 text-2xl'>
//             You can get Referral from
//         </h1>
//         <div className='flex m-auto px-4 grid grid-cols-3  gap-5 justify-center'>
//         <p class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
//     <img class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={apple} alt="" />
//     <div class="flex flex-col justify-between p-4 leading-normal">
//         <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Apple Inc. </h5>
//         <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Apple Inc. is an American multinational technology company headquartered in Cupertino, California, United States.</p>
//     </div>
// </p>
// <p class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
//     <img class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={adobe} alt="" />
//     <div class="flex flex-col justify-between p-4 leading-normal">
//         <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Adobe Inc. </h5>
//         <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Apple Inc. is an American multinational technology company headquartered in Cupertino, California, United States.</p>
//     </div>
// </p>
// <p class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
//     <img class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={amazon} alt="" />
//     <div class="flex flex-col justify-between p-4 leading-normal">
//         <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Amazon </h5>
//         <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Apple Inc. is an American multinational technology company headquartered in Cupertino, California, United States.</p>
//     </div>
// </p>
// <p class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
//     <img class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={gs} alt="" />
//     <div class="flex flex-col justify-between p-4 leading-normal">
//         <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Goldman Sachs </h5>
//         <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Apple Inc. is an American multinational technology company headquartered in Cupertino, California, United States.</p>
//     </div>
// </p>
// <p class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
//     <img class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={google} alt="" />
//     <div class="flex flex-col justify-between p-4 leading-normal">
//         <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Google </h5>
//         <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Apple Inc. is an American multinational technology company headquartered in Cupertino, California, United States.</p>
//     </div>
// </p>
// <p class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
//     <img class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={adobe} alt="" />
//     <div class="flex flex-col justify-between p-4 leading-normal">
//         <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Adobe Inc. </h5>
//         <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Apple Inc. is an American multinational technology company headquartered in Cupertino, California, United States.</p>
//     </div>
// </p>
// <p class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
//     <img class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={adobe} alt="" />
//     <div class="flex flex-col justify-between p-4 leading-normal">
//         <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Adobe Inc. </h5>
//         <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Apple Inc. is an American multinational technology company headquartered in Cupertino, California, United States.</p>
//     </div>
// </p>
//     </div>
//     </div>
//   )
// }

// export default Company
