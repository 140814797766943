import React, { useState } from "react";
import ReadOnlyModal from "../../TemplatesModal/ReadOnlyModal";

const CardView = ({ email }) => {
  let emailData = email?.body;
  const MAX_TEXT_LENGTH = 100;
  const limitedText =
    emailData.length > MAX_TEXT_LENGTH
      ? `${emailData.substring(0, MAX_TEXT_LENGTH)}...`
      : emailData;

  return (
    <>
      <div className="bg-red p-4 rounded-lg shadow h-40 flex flex-col justify-center items-center">
        <p className="text-black text-center">{limitedText}</p>
      </div>
    </>
  );
};

export default CardView;
