import React, { useState, useEffect } from "react";
// import data from "../../data/dataTable.json";
import axios from "axios";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import * as backend from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function JobTrack() {
  const [requests, setRequests] = useState([]);
  // const [email, setEmail] = useState(localStorage.getItem("email"));
  const email = localStorage.getItem("email");

  useEffect(() => {
    fetch(backend.backend + "userData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.json();
      })
      .then((data) => {
        setRequests(data.requests);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [requests]);

  const showFeedback = (feedback, status) => {
    if (feedback === "Pending") {
      toast.warning("Pending...", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if ((status === "Referred") | (status === "Accepted")) {
      toast.success(feedback, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(feedback, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // console.log(requests);
  return (
    <div className="bg-slate-200 h-full">
      <div class="w-auto mx-auto">
        <h1 className="flex justify-center pt-7 text-xl sm:text-2xl md:text-3x">
          Job Tracking!
        </h1>
        {requests.length > 0 ? (
          <div class="bg-white shadow-md rounded my-6">
            <table class="text-left sm:w-full border-collapse table-fixed">
              <thead>
                <tr>
                  <th class="py-7 sm:px-5 md:px-10 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                    S.No
                  </th>
                  <th class="py-4 sm:px-5 md:px-10 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                    Date
                  </th>
                  <th class="py-4 sm:px-5 md:px-10 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                    Company
                  </th>
                  <th class="py-4 sm:px-5 md:px-10 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                    Job
                  </th>
                  <th class="py-4 sm:px-5 md:px-10 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                    Resume
                  </th>
                  <th class="py-4 sm:px-5 md:px-10 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                    Status
                  </th>
                  <th class="py-4 sm:px-5 md:px-10 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                    Remarks
                  </th>
                </tr>
              </thead>
              <tbody>
                {requests.map((student, index) => (
                  <tr className="hover:bg-grey-lighter" key={index}>
                    <td className="py-4 sm:px-5 md:px-10 border-b border-grey-light sm:overflow-hidden sm:truncate">
                      {index + 1}
                    </td>
                    <td className="py-4 sm:px-5 md:px-10 border-b border-grey-light">
                      {student.createdAt
                        ?.substr(0, 10)
                        .split("-")
                        .reverse()
                        .join("-")}
                    </td>
                    <td className="py-4 sm:px-5 md:px-10 border-b border-grey-light sm:overflow-hidden sm:truncate">
                      {student.companyName}
                    </td>
                    <td className="py-4 sm:px-5 md:px-10 border-b border-grey-light sm:overflow-hidden sm:truncate text-sm">
                      {student.jobId}
                    </td>
                    <td className="py-4 sm:px-5 md:px-10 border-b border-grey-light overflow-hidden truncate text-sm">
                      <a href={student.resumeLink} target="_blank">
                        {student.resumeLink}
                      </a>
                    </td>
                    <td className="py-4 sm:px-5 md:px-10 border-b border-gray-200 bg-white text-sm">
                      <span
                        className={`relative inline-block px-3 py-1 font-semibold leading-tight ${
                          student.status === "Accepted"
                            ? "text-green-700"
                            : student.status === "Pending"
                            ? "text-yellow-400"
                            : "text-red-600"
                        }`}
                      >
                        <span
                          aria-hidden
                          className={`absolute inset-0 opacity-50 rounded-full ${
                            student.status === "Accepted"
                              ? "bg-green-200"
                              : student.status === "Pending"
                              ? "bg-yellow-200"
                              : "bg-red-200"
                          }`}
                        ></span>
                        <span className="relative">{student.status}</span>
                      </span>
                    </td>
                    <td
                      className="py-4 sm:px-5 md:px-10 border-b border-grey-light cursor-pointer overflow-hidden truncate text-sm"
                      onClick={(e) =>
                        showFeedback(student.remarks, student.status)
                      }
                    >
                      {student.remarks}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="flex pt-5 justify-center items-center align-middle flex-col">
            <h2>No Pending requests</h2>
            <Link to="/requestportal">
              <button className="pl-2 pr-2 p-1 bg-purple-800 text-white rounded-md">
                Make a request?
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default JobTrack;
